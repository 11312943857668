<template>
  <div class="dropdown" tabindex="0" @focus="open" @keydown="handleKeyDown" ref="dropdownComponent" @blur="closeViaBlur">
    <div class="dropdown-outside" @mousedown.prevent="opened ? close() : open()">
      <p v-if="value && value[displayKey]" :class="{ zIndex: opened }" class="brand-text">{{ title }}</p>
      <p :class="{ normalColor: value && value[displayKey] }">{{ value && value[displayKey] ? value[displayKey] : title }}</p>
      <div class="imageContainer">
        <div class="image-box-dropdown"></div>
      </div>
    </div>
    <div class="dropdown-opened" v-if="opened" ref="dropdownComponentList">
      <div class="dropdown-title">
        <p>{{ value && value[displayKey] ? value[displayKey] : title }}</p>
        <div class="imageContainer">
          <div @click="close" class="image-box-dropdown close-dropdown"></div>
        </div>
      </div>

      <ul>
        <li
          v-for="(option, index) in options"
          :key="index"
          @mouseenter="focusedItemIndex = index"
          :class="{
            highlighted: focusedItemIndex === index,
          }"
        >
          <input type="radio" :value="option" :id="option[valueKey] + '_' + index" v-model="value" />
          <label :for="option[valueKey] + '_' + index" :title="option[displayKey]">{{ option[displayKey] }}</label>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CustomDropdownCampaign',
  $emits: ['update:modelValue'],
  props: {
    options: {},
    title: {},
    valueKey: { default: 'value' },
    displayKey: { default: 'name' },
    default: { default: null },
    modelValue: {},
  },
  data() {
    return {
      value: [],
      opened: false,
      typed: '',
      focusedItemIndex: -1,
      timeoutTyped: 0,
    };
  },
  watch: {
    value() {
      this.$emit('update:modelValue', this.value[this.valueKey]);
      this.close();
    },
    default() {
      this.setDefault();
    },
  },
  mounted() {
    this.setDefault();
  },
  methods: {
    setDefault() {
      if (this.default) {
        for (let i = 0; i < this.options.length; i++) {
          if (this.options[i][this.valueKey] === this.default) {
            this.value = this.options[i];
            break;
          }
        }
      }
    },

    open() {
      if (this.opened) {
        return;
      }
      this.opened = true;
      this.$nextTick(() => {
        this.$refs.dropdownComponent.focus();
        for (let i = 0; i < this.options?.length; i++) {
          if (this.options[i][this.valueKey] === this.modelValue) {
            this.focusedItemIndex = i;
            console.log(this.$refs.dropdownComponentList.scrollHeight, this.$refs.dropdownComponentList.clientHeight);
            if (this.$refs.dropdownComponentList.scrollHeight >= this.$refs.dropdownComponentList.clientHeight) {
              const focusedItem = this.$refs.dropdownComponentList.querySelectorAll('li')[this.focusedItemIndex];
              focusedItem?.scrollIntoView({ block: 'nearest' });
            }
          }
        }
      });
    },
    close() {
      if (!this.opened) {
        return;
      }
      this.opened = false;
      this.focusedItemIndex = -1;
    },
    handleKeyDown(e) {
      if (e.target !== this.$refs.dropdownComponent) {
        return;
      }

      if (e.key === 'Escape' || e.key === 'Tab') {
        this.close();
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        if (this.focusedItemIndex + 1 >= this.options.length) {
          return;
        }
        this.focusedItemIndex++;

        const focusedItem = this.$refs.dropdownComponentList?.querySelectorAll('li')[this.focusedItemIndex];
        focusedItem?.scrollIntoView({ block: 'nearest' });
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        if (this.focusedItemIndex === -1) {
          this.focusedItemIndex = this.options.length - 1;
        } else if (this.focusedItemIndex > 0) {
          this.focusedItemIndex--;
        }
        const focusedItem = this.$refs.dropdownComponentList?.querySelectorAll('li')[this.focusedItemIndex];
        focusedItem?.scrollIntoView({ block: 'nearest' });
      } else if (e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar' || e.key === 'Space') {
        e.preventDefault();
        if (this.opened) {
          if (this.focusedItemIndex >= 0 && this.options[this.focusedItemIndex]) {
            this.value = this.options[this.focusedItemIndex];
            this.close();
          }
        } else {
          this.open();
        }
      } else if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 96 && e.keyCode <= 105)) {
        e.preventDefault();
        this.typed += e.key;
        const filteredOptions = this.options?.filter((option) => {
          return option[this.displayKey].toLowerCase().startsWith(this.typed.toLowerCase());
        });
        if (this.timeoutTyped) {
          clearTimeout(this.timeoutTyped);
        }

        this.timeoutTyped = window.setTimeout(() => {
          this.typed = '';
        }, 1000);
        if (filteredOptions && filteredOptions?.length > 0) {
          this.focusedItemIndex = this.options?.indexOf(filteredOptions[0]) ?? -1;
          const focusedItem = this.$refs.dropdownComponentList?.querySelectorAll('li')[this.focusedItemIndex];
          focusedItem?.scrollIntoView({ block: 'nearest' });
          setTimeout(() => {
            this.focusedItemIndex = this.options?.indexOf(filteredOptions[0]) ?? -1;
          }, 10);
        }
      }
    },
    closeViaBlur(e) {
      if (!this.$refs.dropdownComponent?.contains(e.relatedTarget) && !this.$refs.dropdownComponentList?.contains(e.relatedTarget)) {
        this.close();
      }
    },
  },
};
</script>
